import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../components/Layout"
import { slugify } from "../../../plugins/url"
import Content from "./components/Content"
import { ScrollToElement } from "./components/hooks"
import Navigation from "./components/Navigation"
import { stepsDTO } from "./DTO/stepsDTO"

const HRGuidePage = ({ pageContext, data, location }) => {
  const {
    allStrapiGuideStepItems,
    strapiGuideSteps,
    strapiTranslation,
    strapiGetAQuoteV2,
    strapiRessource,
    strapiPartnerList,
  } = data
  const aleaToken = pageContext.general.aleaToken
  const nodes = stepsDTO(
    allStrapiGuideStepItems,
    strapiGuideSteps,
    strapiGetAQuoteV2,
    strapiRessource,
    strapiTranslation,
    pageContext
  )
  const [isSelectedStep, setIsSelectedStep] = useState(
    nodes.steps.find(el => el)
  )
  const [isStepPage, setStepPage] = useState(isSelectedStep.guide_step_items[0])
  const [isAnchor, setIsAnchor] = useState({
    stepIndex: 0,
    pageIndex: 0,
    elementId: nodes.steps
      .find(el => el)
      .guide_step_items.find(l => l)
      .guideContent.find(f => f).id,
  })
  const [tempId, setTempId] = useState(isAnchor.elementId)
  const [open, setOpen] = useState(true)
  const [checkList, setCheckList] = useState(nodes.checkList)
  const [elInView, setElInView] = useState([])

  useEffect(() => {
    const params = window.location.href.split("#")
    if (params[1]) {
      nodes.menu.items.map((step, stepIndex) => {
        if (slugify(step.name) === params[1]) {
          setIsSelectedStep(nodes.steps[stepIndex])
          setTempId(
            nodes.steps[stepIndex].guide_step_items[0]?.guideContent[0].id
          )
          setStepPage(nodes.steps[stepIndex].guide_step_items[0])
          setIsAnchor({
            ...isAnchor,
            stepIndex: stepIndex,
            pageIndex: 0,
            elementId:
              nodes.steps[stepIndex].guide_step_items[0]?.guideContent[0].id,
          })
        }

        step.children.items?.map((page, pageIndex) => {
          if (slugify(page.name) === params[1]) {
            const anchorIndex = nodes.steps[stepIndex].guide_step_items.indexOf(
              nodes.steps[stepIndex].guide_step_items.find(el =>
                el.guideContent.find(l => l.id === page.id)
              )
            )
            setIsSelectedStep(nodes.steps[stepIndex])
            setTempId(page.id)
            setStepPage(nodes.steps[stepIndex].guide_step_items[anchorIndex])
            setIsAnchor({
              ...isAnchor,
              stepIndex: stepIndex,
              pageIndex: anchorIndex,
              elementId: page.id,
            })
            setTimeout(() => {
              ScrollToElement(page.id)
            }, 200)
          }
          page.children.items?.map((element, elIndex) => {
            if (slugify(element.name) === params[1]) {
              const anchorIndex1 = nodes.steps[
                stepIndex
              ].guide_step_items.indexOf(
                nodes.steps[stepIndex].guide_step_items.find(el =>
                  el.guideContent.find(l => l.id === element.id)
                )
              )
              setIsSelectedStep(nodes.steps[stepIndex])
              setTempId(page.id)
              setStepPage(nodes.steps[stepIndex].guide_step_items[anchorIndex1])
              setIsAnchor({
                ...isAnchor,
                stepIndex: stepIndex,
                pageIndex: anchorIndex1,
                elementId: element.id,
              })
              setTimeout(() => {
                ScrollToElement(element.id)
              }, 200)
            }
            return false
          })
          return false
        })
        return false
      })
    }
  })

  // console.log("nodes:", nodes)
  // console.log("isAnchor:", isAnchor)
  // console.log("checklist: ", checkList)
  // console.log("advisor ", nodes.advisor)
  // console.log("footer ", nodes.guideFooter)
  // console.log("strapiGuideSteps:", strapiGuideSteps)

  return (
    <>
      <Layout
        props={pageContext.general}
        meta={strapiGuideSteps.meta}
        localizations={strapiGuideSteps.localizations.map(m => m.locale)}
        location={location}
        lang={strapiGuideSteps.locale}
        hideNewsletter={true}
        token={aleaToken}
        footerForm={null}
        hideIcon={true}
        guideFooter={nodes.guideFooter}
        hideLanguage={["fr"]}
        ogImage={strapiGuideSteps.footer.image.localFile.publicURL}
        isB2B={strapiGuideSteps.isB2B}
      >
        <div id="page-guide" className=" tw-flex tw-flex-col">
          <h1 className="tw-bg-white md:tw-hidden tw-flex tw-basis-full tw-border-solid tw-border-0 tw-border-b-2 tw-border-grey-100 tw-items-center tw-py-1 tw-my-0 tw-text-lg tw-font-loraBold tw-text-ming tw-justify-center">
            {nodes.title}
          </h1>
          <div className="tw-grow md:tw-flex-col tw-overflow-hidden tw-flex-row md:tw-h-full tw-h-[calc(100vh_-_118px)] tw-relative tw-flex">
            <Navigation
              stepArray={nodes.steps}
              selected={{
                isSelectedStep,
                setIsSelectedStep,
              }}
              stepPage={{ isStepPage, setStepPage }}
              anchor={{ isAnchor, setIsAnchor }}
              menu={nodes.menu}
              open={open}
              setOpen={setOpen}
              temp={{ tempId, setTempId }}
              inView={{ elInView, setElInView }}
            />
            <Content
              translation={strapiTranslation.form}
              pageLabels={strapiGuideSteps.pageLabels}
              getAQuote={strapiGetAQuoteV2.getAQuote}
              stepArray={nodes.steps}
              lang={strapiGuideSteps.locale}
              token={aleaToken}
              location={location}
              title={nodes.title}
              affiliateForm={{
                title: strapiGuideSteps.pageLabels.quoteFormTitle,
                submitButtonLabel: strapiGuideSteps.pageLabels.quoteFormButton,
              }}
              selected={{
                isSelectedStep,
                setIsSelectedStep,
              }}
              advisor={nodes.advisor}
              inView={{ elInView, setElInView }}
              stepPage={{ isStepPage, setStepPage }}
              anchor={{ isAnchor, setIsAnchor }}
              open={open}
              setOpen={setOpen}
              temp={{ tempId, setTempId }}
              menu={nodes.menu}
              check={{ checkList, setCheckList }}
              partners={strapiPartnerList}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HRGuidePage

export const query = graphql`
  query guideQuery($id: Int, $locale: String, $page: [Int]) {
    allStrapiGuideStepItems(filter: { strapiId: { in: $page } }) {
      nodes {
        title
        strapiId
        enableContentChecklist
        weight
        guideContent {
          title
          id
          anchorLevel
          content
          highlight {
            content
            footnote
            typeHighlight
            cta {
              buttonLabel
              template
              name
              url
            }
            imageCTA {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          glossary_tips {
            content
            title
          }
          case_study {
            content
            id
            subtitle
            title
            icon {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        checklist {
          id
          name
          guide_checklist_group {
            id
            label
            title
            locale
            weight
          }
        }
      }
    }
    strapiGuideSteps(strapiId: { eq: $id }, locale: { eq: $locale }) {
      title
      locale
      isB2B
      meta {
        description
        noIndex
        title
      }
      localizations {
        locale
      }
      advisor {
        calendyUrl
        id
        our_teams {
          name
          jobTitle
          order
          specialization
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              url
              publicURL
            }
          }
        }
      }
      guide {
        stepName
        id
        enablePreface
        stepImage {
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1)
            }
          }
        }
        guide_step_items {
          id
          weight
          title
          enableContentChecklist
        }
      }
      footer {
        buttonLabel
        id
        terms
        title
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      pageLabels {
        checkListPageTitle
        completeChecklistTitle
        completeStepButton
        navBack
        navNext
        nextStepbutton
        quoteFormButton
        quoteFormTitle
        restartStepButton
        toDoChecklistTitle
        startHRGuideButton
      }
    }
    strapiRessource(locale: { eq: $locale }) {
      locale
      browse {
        factCheckedLabel
        ContentsLabel
        readmoreLabel
        lastUpdateLabel
        moreArticleLabel
      }
      advisorMenu {
        title
        requestBtnLabel
        callBtnLabel
        calendlyUrl
        enable
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          alternativeText
        }
      }
    }
    strapiPartnerList(locale: { eq: $locale }) {
      locale
      title
      cta {
        buttonLabel
        locale
        url
        template
        name
        id
      }
      partners {
        slug
        locale
        name
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        logoTransparency {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      strapiId
    }
    strapiTranslation(locale: { eq: $locale }) {
      locale
      form {
        invalidFormMsg
        bookCallLabel
        companyNameLabel
        companyNamePlaceholder
        emailError
        emailLabel
        emailPlaceholder
        getQuoteLabel
        invalidFormMsg
        nameError
        nameLabel
        namePlaceholder
        phoneNumberError
        phoneNumberLabel
        phoneNumberPlaceholder
        submitLabel
        submissionMsg
      }
    }
    strapiGetAQuoteV2(locale: { eq: $locale }) {
      title
      slug
      meta {
        title
        description
      }
      getAQuote {
        formTitle
        insuranceForLabel
        insuranceTypeLabel
        title
        insuranceFor {
          label
          selectByDefault
          pipedriveId
        }
        insuranceType {
          label
          pipedriveId
          selectByDefault
        }
        buttonLabel
      }
      locale
    }
  }
`
